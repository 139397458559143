module.exports = function (gantt) {
  var linksBuilder = require("../../core/relations/links_builder")(gantt);

  var graphHelper = require("../../core/relations/graph_helper")(gantt);

  gantt._isProjectEnd = function (task) {
    return !this._hasDuration({
      start_date: task.end_date,
      end_date: this._getProjectEnd(),
      task: task
    });
  };

  return {
    _needRecalc: true,
    _cache: null,
    reset: function reset() {
      this._needRecalc = true;
      this._cache = null;
    },
    _isRecalcNeeded: function _isRecalcNeeded() {
      return this._needRecalc;
    },
    _getLinks: function _getLinks() {
      var links = linksBuilder.getLinkedTasks();
      return graphHelper.groupAdjacentEdges(links);
    },
    _calculateBranch: function _calculateBranch(task, path, criticalTasks, adjacentLinks) {
      path[task.id] = true;

      if (criticalTasks[task.id] !== undefined) {
        return;
      }

      var stack = [task];

      while (stack.length) {
        task = stack.pop();

        if (criticalTasks[task.id] || gantt._isProjectEnd(task)) {
          criticalTasks[task.id] = true;

          while (stack.length) {
            var task = stack.pop();
            criticalTasks[task.id] = true;
          }
        } else {
          criticalTasks[task.id] = false;
          var successors = adjacentLinks[task.id] || [];

          for (var i = 0; i < successors.length; i++) {
            var next = gantt.getTask(successors[i].target);

            if (gantt._getSlack(task, next, successors[i]) <= 0 && (!path[next.id] || criticalTasks[next.id])) {
              path[next.id] = true;
              stack.push(task);
              stack.push(next);
              break;
            }
          }
        }
      }
    },
    _calculateSummaryTasks: function _calculateSummaryTasks(summaryHash, criticalHash) {
      for (var i in criticalHash) {
        if (criticalHash[i]) {
          var parent = gantt.getParent(i);

          while (summaryHash[parent] === undefined && gantt.isTaskExists(parent)) {
            summaryHash[parent] = true;
            parent = gantt.getParent(parent);
          }
        }
      }

      for (var i in summaryHash) {
        criticalHash[i] = !!summaryHash[i];
      }
    },
    _calculate: function calculateCriticalPath() {
      var criticalTasks = {};
      var clearCache = false;
      var path = {};

      if (!gantt._isLinksCacheEnabled()) {
        gantt._startLinksCache();

        clearCache = true;
      }

      var links = this._getLinks();

      var summaryTasks = {};
      gantt.eachTask(function (task) {
        if (path[task.id]) return;

        if (gantt.isSummaryTask(task)) {
          summaryTasks[task.id] = undefined;
        } else {
          this._calculateBranch(task, path, criticalTasks, links);
        }
      }, gantt.config.root_id, this);

      this._calculateSummaryTasks(summaryTasks, criticalTasks);

      if (clearCache) gantt._endLinksCache();
      return criticalTasks;
    },
    isCriticalTask: function isCriticalTask(task) {
      if (!task) return false;

      if (this._isRecalcNeeded()) {
        this._cache = this._calculate();
        this._needRecalc = false;
      }

      return this._cache[task.id];
    },
    init: function init() {
      var resetCache = gantt.bind(function () {
        this.reset();
        return true;
      }, this);
      var handleTaskIdChange = gantt.bind(function (oldId, newId) {
        if (this._cache) {
          this._cache[newId] = this._cache[oldId];
          delete this._cache[oldId];
        }

        return true;
      }, this);
      gantt.attachEvent("onAfterLinkAdd", resetCache);
      gantt.attachEvent("onAfterLinkUpdate", resetCache);
      gantt.attachEvent("onAfterLinkDelete", resetCache);
      gantt.attachEvent("onAfterTaskAdd", resetCache);
      gantt.attachEvent("onTaskIdChange", handleTaskIdChange);
      gantt.attachEvent("onAfterTaskUpdate", resetCache);
      gantt.attachEvent("onAfterTaskDelete", resetCache);
      gantt.attachEvent("onParse", resetCache);
      gantt.attachEvent("onClearAll", resetCache);

      var criticalPathHandler = function criticalPathHandler() {
        if (gantt.config.highlight_critical_path && !gantt.getState("batchUpdate").batch_update) gantt.render();
      };

      gantt.attachEvent("onAfterLinkAdd", criticalPathHandler);
      gantt.attachEvent("onAfterLinkUpdate", criticalPathHandler);
      gantt.attachEvent("onAfterLinkDelete", criticalPathHandler);
      gantt.attachEvent("onAfterTaskAdd", criticalPathHandler);
      gantt.attachEvent("onTaskIdChange", function (oldId, newId) {
        if (gantt.config.highlight_critical_path && gantt.isTaskExists(newId)) {
          gantt.refreshTask(newId);
        }

        return true;
      });
      gantt.attachEvent("onAfterTaskUpdate", criticalPathHandler);
      gantt.attachEvent("onAfterTaskDelete", criticalPathHandler);
    }
  };
};