function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

module.exports = function (gantt) {
  var linksBuilder = require("../../core/relations/links_builder")(gantt);

  var _private = {
    _freeSlack: {},
    _totalSlack: {},
    _slackNeedCalculate: true,
    _linkedTasksById: {},
    _successorsByTaskId: {},
    _calculateTotalSlack: function _calculateTotalSlack() {
      var linksByTaskId = this._linkedTasksById;
      var successorsByTaskId = this._successorsByTaskId;
      this._totalSlack = {};
      var linkedTasks = linksBuilder.getLinkedTasks();
      linkedTasks.forEach(function (entry) {
        // get successors
        if (!successorsByTaskId[entry.source]) {
          successorsByTaskId[entry.source] = {
            linked: []
          };
        }

        successorsByTaskId[entry.source].linked.push({
          target: entry.target
        }); // get predecessors

        if (!linksByTaskId[entry.target]) {
          linksByTaskId[entry.target] = {
            source: entry.source,
            linked: []
          };
        }

        linksByTaskId[entry.target].linked.push({
          source: entry.source
        });
      });
      var totalSlackByTaskId = {}; // first data for the calculation

      var tasks = gantt.getTaskByTime(); // second data for the calculation

      var secondIterationOrder = [];
      var secondIterationData = {};

      var slackCalculation = function slackCalculation(stackElement) {
        var calculation = _private._predecessorChainSlackCount(stackElement.task, stackElement.additional, stackElement.chain);

        if (typeof calculation === "undefined") {
          return;
        } else if (_typeof(calculation) == "object") {
          calculation.predecessorStack.forEach(function (predecessor) {
            if (!secondIterationData[predecessor.task.id]) {
              secondIterationOrder.push(predecessor.task.id);
              secondIterationData[predecessor.task.id] = predecessor;
            } else {
              if (secondIterationData[predecessor.task.id].additional > predecessor.additional) {
                secondIterationData[predecessor.task.id] = predecessor;
              } // removing duplicate tasks in the order


              while (secondIterationOrder.indexOf(predecessor.task.id) != -1) {
                var duplicateIndex = secondIterationOrder.indexOf(predecessor.task.id);
                secondIterationOrder.splice(duplicateIndex, 1);
              }

              secondIterationOrder.push(predecessor.task.id);
            }
          });
          totalSlackByTaskId[stackElement.task.id] = calculation.taskSlack;
        } else {
          totalSlackByTaskId[stackElement.task.id] = calculation;
        }
      }; // start from the latest tasks


      tasks.sort(function (a, b) {
        return +b.end_date - +a.end_date;
      });

      while (tasks.length) {
        var task = tasks.shift();
        var stackElement = {
          task: task,
          additional: 0,
          chain: false
        };

        if (!gantt.isSummaryTask(stackElement.task)) {
          slackCalculation(stackElement);
        }
      }

      while (secondIterationOrder.length) {
        var taskId = secondIterationOrder.shift();
        var stackElement = secondIterationData[taskId];
        slackCalculation(stackElement);
      }

      gantt._slacksChanged = false;
      this._slackNeedCalculate = false;
      return this._totalSlack;
    },
    _predecessorChainSlackCount: function _predecessorChainSlackCount(task, additional, chain) {
      var _this = this;

      if (!this._successorsByTaskId[task.id]) {
        this._totalSlack[task.id] = gantt.calculateDuration(task.end_date, gantt.getSubtaskDates().end_date);
        additional = this._totalSlack[task.id]; // this is the last chain element, so we can calculate the slack for all its predecessors

        chain = true;
      } else {
        chain = chain || false;
      }

      if (!chain) {
        // do not calculate slack if the task has successors, but chain calculation has not started yet
        return undefined;
      }

      var linkedPredecessors = this._linkedTasksById[task.id];

      if (linkedPredecessors) {
        if (linkedPredecessors.linked.length > -1) {
          var predecessorsLinks = linkedPredecessors.linked;
          var predecessorStack = [];
          predecessorsLinks.forEach(function (predecessorsLink) {
            var predecessor = gantt.getTask(predecessorsLink.source);

            var predecessorSlack = gantt.getSlack(predecessor, task) + _this._totalSlack[task.id];

            if (_this._totalSlack[predecessor.id] === undefined || _this._totalSlack[predecessor.id] > predecessorSlack) {
              _this._totalSlack[predecessor.id] = predecessorSlack;
            }

            if (chain) {
              predecessorStack.push({
                task: predecessor,
                additional: _this._totalSlack[predecessor.id],
                chain: true
              });
            }
          });
          return {
            taskSlack: this._totalSlack[task.id],
            predecessorStack: predecessorStack
          };
        }
      }

      this._totalSlack;
      return this._totalSlack[task.id];
    },
    _calculateTaskSlack: function _calculateTaskSlack(task) {
      var slack;

      if (task.$source && task.$source.length) {
        slack = this._calculateRelationSlack(task);
      } else {
        slack = this._calculateHierarchySlack(task);
      }

      return slack;
    },
    _calculateRelationSlack: function _calculateRelationSlack(task) {
      var minSlack = 0,
          slack,
          links = task.$source;

      for (var i = 0; i < links.length; i++) {
        slack = this._calculateLinkSlack(links[i]);

        if (minSlack > slack || i === 0) {
          minSlack = slack;
        }
      }

      return minSlack;
    },
    _calculateLinkSlack: function _calculateLinkSlack(linkId) {
      var link = gantt.getLink(linkId);
      var slack = 0;

      if (gantt.isTaskExists(link.source) && gantt.isTaskExists(link.target)) {
        slack = gantt.getSlack(gantt.getTask(link.source), gantt.getTask(link.target));
      }

      return slack;
    },
    _calculateHierarchySlack: function _calculateHierarchySlack(task) {
      var slack = 0;
      var parentSlack = null;
      var from;
      var to = gantt.getSubtaskDates().end_date;

      if (gantt.isTaskExists(task.parent)) {
        from = gantt.getSubtaskDates(task.id).end_date || task.end_date;
        var parent = gantt.getTask(task.parent);

        if (parent.type == gantt.config.types.project && parent.$source && parent.$source.length) {
          parentSlack = this._calculateRelationSlack(parent);
        }
      } else {
        from = task.end_date;
      }

      slack = Math.max(gantt.calculateDuration(from, to), 0);

      if (parentSlack !== null && parentSlack < slack) {
        return parentSlack;
      } else {
        return slack;
      }
    },
    _resetTotalSlackCache: function _resetTotalSlackCache() {
      this._slackNeedCalculate = true;
    },
    _shouldCalculateTotalSlack: function _shouldCalculateTotalSlack() {
      return this._slackNeedCalculate;
    },
    getFreeSlack: function getFreeSlack(task) {
      if (!gantt.isTaskExists(task.id)) {
        return 0;
      }

      if (!this._freeSlack[task.id]) {
        if (gantt.isSummaryTask(task)) {
          this._freeSlack[task.id] = undefined;
        } else {
          this._freeSlack[task.id] = this._calculateTaskSlack(task);
        }
      }

      return this._freeSlack[task.id];
    },
    getTotalSlack: function getTotalSlack(task) {
      if (this._shouldCalculateTotalSlack()) {
        this._calculateTotalSlack();
      }

      if (task === undefined) {
        return this._totalSlack;
      }

      if (task.id !== undefined) {
        // return this._chainSlackCount(task);
        return this._totalSlack[task.id];
      }

      return this._totalSlack[task] || 0;
    },
    dropCachedFreeSlack: function dropCachedFreeSlack() {
      this._linkedTasksById = {};
      this._successorsByTaskId = {};
      this._freeSlack = {};

      this._resetTotalSlackCache();
    },
    init: function init() {
      function slackHandler() {
        _private.dropCachedFreeSlack();
      }

      gantt.attachEvent("onAfterLinkAdd", slackHandler);
      gantt.attachEvent("onTaskIdChange", slackHandler);
      gantt.attachEvent("onAfterLinkUpdate", slackHandler);
      gantt.attachEvent("onAfterLinkDelete", slackHandler);
      gantt.attachEvent("onAfterTaskAdd", slackHandler);
      gantt.attachEvent("onAfterTaskUpdate", slackHandler);
      gantt.attachEvent("onAfterTaskDelete", slackHandler);
      gantt.attachEvent("onRowDragEnd", slackHandler);
      gantt.attachEvent("onAfterTaskMove", slackHandler);
    }
  };
  return _private;
};